let appMode = process.env.REACT_APP_ENV;
let ASSET_URL =
  "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/tawasi-s3/";
let URL;
let socketUrl;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://dev.backend.tawasi.online/api/";
  socketUrl = "https://dev.backend.tawasi.online/";
  // URL = "http://localhost:3000/api/";
} else {
  // URL = "http://localhost:5500/api/";
  URL = "https://backend.tawasi.online/api/";
  socketUrl = "https://backend.tawasi.online/";
}

let apiPath = {
  baseURL: URL,
  socketUrl,
  assetURL: ASSET_URL,
  dashboard: "vendor/dashboard",
  changeOnline: "vendor/auth/status/online",

  listOrder: "vendor/order/list",
  viewOrder: "vendor/order/view",
  statusOrder: "vendor/order/status",

  listTransaction: "vendor/transaction/list",
  viewTransaction: "vendor/transaction/view",

  // Auth API
  logout: "vendor/auth/logout",
  login: "vendor/auth/login",

  profile: "vendor/auth/get-profile",
  updateProfile: "vendor/auth/update-profile",

  changePassword: "vendor/auth/change-password",
  updateAppSetting: "vendor/auth/update-app-setting",

  forgotPassword: "vendor/auth/forgot-password",
  verifyOTP: "vendor/auth/verify-otp",
  resetPassword: "vendor/auth/reset-password",

  // Customer APIs
  listCustomer: "vendor/customer",
  addEditCustomer: "vendor/customer",
  deleteCustomer: "vendor/delete",
  statusCustomer: "vendor/customer/status",
  viewCustomer: "vendor/customer/view",
  importCustomer: "vendor/customer/import-file",
  // imageUpload: "image-upload",

  imageUpload: "image-upload-front",

  // SubAdmin APIs
  subAdmin: "vendor/sub-admin",
  addEditSubAdmin: "vendor/sub-admin",
  statusSubAdmin: "vendor/sub-vendor/status",
  viewSubAdmin: "vendor/sub-vendor/view",
  getModule: "vendor/sub-vendor/module-list",
  addPermission: "vendor/sub-vendor/add-permission",

  //collector
  collector: "vendor/collector",

  // driver APIs
  driver: "vendor/driver",
  importDealer: "vendor/dealer/import-file",
  checkCode: "vendor/discount/check-code",
  discount: "vendor/discount",
  revenue: "vendor/revenue",
  deposit: "vendor/deposit",

  review: "vendor/review",
  invoice: "vendor/invoice",
  paymentHistory: "vendor/invoice/payment/approved",
  pendingApproval: "vendor/invoice/payment/pending-approval",
  filters: "vendor/invoice/filters/approved",
  pendingPaymentUpdate: "vendor/invoice/update",

  banner: "vendor/banner",

  // Content APIs
  content: "vendor/content",

  // EmailTemplate APIs
  listEmailTemplate: "vendor/email-template/list",
  addEditEmailTemplate: "vendor/email-template/add-edit",
  statusEmailTemplate: "vendor/email-template/status",
  viewEmailTemplate: "vendor/email-template/view",

  // Blog APIs
  listBlog: "vendor/blog/list",
  addEditBlog: "vendor/blog/add-edit",
  statusBlog: "vendor/blog/status",
  viewBlog: "vendor/blog/view",

  //category
  listCategory: "vendor/category",
  statusCategory: "vendor/category/status",

  //restaurant
  restaurant: "vendor/restaurant",

  getAppSetting: "common/app-setting",

  // upload menu
  uploadMenu: "vendor/food-item/upload",

  // Food Size
  foodSize: "vendor/food-size",

  // food choices
  foodChoices: "vendor/food-choice",
  driverRequest: "vendor/driver-request",

  // ingredients (add ons)
  ingredientCategories: "vendor/ingredient/category",
  ingredients: "vendor/ingredient",

  //food items
  food: "vendor/food-item",

  //order
  order: "vendor/order",
  schedule: "vendor/schedule-order",

  //Vendor apis

  notification: "vendor/notification",

  settings: {
    language: "vendor/auth/language-update",
  },

  common: {
    restaurants: "restaurants",
    restaurantCategories: "categories",

    foodCategories: "food-categories",
    selectedFoodCategories: "selected-food-categories",

    items: "food-item",

    ingredientCategories: "ingredient-categories",
    ingredients: "ingredients",

    foodSize: "food-size",

    choices: "food-choice",

    countries: "",
    cities: "",
  },

  //Settings Api
  addAccount: "vendor/auth/add-account",

  // Auth API
  logout: "vendor/auth/logout",
  signUp: "vendor/auth/sign-up",
  sendOtp: "vendor/auth/send-otp",
};

export default apiPath;
